<template>
  <div>
    <b-sidebar
      id="sidebar-store-campaign"
      sidebar-class="sidebar-lg"
      :visible="newCampaignSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateNewCampaignSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Nova campanha</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Apelido"
            label-for="new-campaign-alias"
          >
            <b-form-input
              id="new-campaign-alias"
              v-model="alias"
              :class="{ 'is-invalid': v$.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.alias.required.$invalid">
                Você deve informar um apelido
              </span>
              <span v-if="v$.alias.regex.$invalid">
                Utilize apenas letras, números e espaços
              </span>
            </div>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ saving ? 'Salvando...' : 'Salvar' }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BAvatar, BButton, BFormInvalidFeedback, BFormTextarea
} from 'bootstrap-vue'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
      alias: undefined
    }
  },
  computed: {
    ...mapGetters({
      newCampaignSidebar: types.NEW_CAMPAIGN_SIDEBAR
    })
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations(){
    return {
      alias: {
        required,
        regex(value) {
          if (value) {
            const pattern = /^[a-zA-Z0-9 ]+$/;
            return pattern.test(value);
          }
          return true;
        },
      },
    }
  },
  methods: {
    ...mapMutations({
      mutateNewCampaignSidebar: types.MUTATE_NEW_CAMPAIGN_SIDEBAR
    }),
    ...mapActions({
      storeCampaign: types.STORE_CAMPAIGN
    }),
    clear(){
      this.alias = undefined
      this.v$.$reset();
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return
      this.saving = true
      let newAlias = this.alias.replace(/\s+/g, "-"); // Substitui espaços por hífens.
      newAlias = newAlias.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Remove acentos.
      this.storeCampaign({
        alias: newAlias
      })
      .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "A campanha foi criada com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateNewCampaignSidebar({visible: false})
          this.newCampaignSidebar.saveAction()
        })
      .catch((err) => {
        if(err.response.status == 405){
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: err.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }else{
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao criar a campanha. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
