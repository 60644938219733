<template>
  <div>
    <b-sidebar
      id="sidebar-comment"
      sidebar-class="sidebar-lg"
      :visible="qrCodeSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateQrCodeSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>Código QR</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <div class="mt-3 flex text-center" v-if="qrCode">
          <qrcode-vue ref="qr" :value="qrCode" :size="width <= 576 ? '200' : '300'" />
          <div class="mt-1">
            <b-button
              id="copy-qr"
              class="btn-icon mr-1"
              variant="flat"
              style="padding: 0; font-size: 1.2rem; color: #fff;"
              @click="copyQR"
            >
              Copiar <feather-icon size="18" class="text-body" icon="CopyIcon" />
            </b-button>
          </div>
          <div class="mt-1">
            <b-button
              id="download-qr"
              class="btn-icon mr-1"
              variant="flat"
              style="padding: 0; font-size: 1.2rem; color: #fff;"
              @click="downloadQR"
            >
              Baixar <feather-icon size="18" class="text-body" icon="DownloadIcon" />
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BButton
} from 'bootstrap-vue'
import { mapMutations, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import { useToast } from 'vue-toastification/composition'
import { useWindowSize } from '@vueuse/core'
import QrcodeVue from 'qrcode.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    QrcodeVue
  },
  directives: {
    Ripple,
  },
  computed: {
    ...mapGetters({
      qrCodeSidebar: types.QR_CODE_SIDEBAR
    })
  },
  data () {
    return {
      qrCode: undefined,
      text: undefined
    }
  },
  setup() {
    const { width } = useWindowSize();

    return { toast: useToast(), width };
  },
  methods: {
    ...mapMutations({
      mutateQrCodeSidebar: types.MUTATE_QR_CODE_SIDEBAR
    }),
    onShow(){
      if(this.qrCodeSidebar.url){
        this.qrCode = this.qrCodeSidebar.url
      }
    },
    async copyQR(){
      const canvasElements = this.$el.getElementsByTagName('canvas')
      if(canvasElements.length > 0){
        const qrCanvas = canvasElements[0]
        qrCanvas.toBlob(blob => {
          navigator.clipboard.write([
            new ClipboardItem({
              'image/png': blob
            })
          ])
        })
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Sucesso",
            text: "Qr copiado para àrea de transferência!",
            icon: "CoffeeIcon",
            variant: "success",
          },
        })
      }else{
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao copiar o QR. Tente clicar com o botão direito do mouse e copiar a imagem manualmente.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    downloadQR(){
      const canvasElements = this.$el.getElementsByTagName('canvas')
      if(canvasElements.length > 0){
        const qrCanvas = canvasElements[0]
        const link = document.createElement('a');
        link.download = 'qrCode.png';
        link.href = qrCanvas.toDataURL()
        link.click()
      }else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao baixar o QR. Tente clicar com o botão direito do mouse e baixar a imagem manualmente.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    clear(){
      this.qrCode = undefined
      this.qrCodeSidebar.url = undefined
    },
  }
}
</script>